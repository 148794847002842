// import InstagramEmbed from 'react-instagram-embed';

export const commands = {
    about: (
        <div>
         <h4>About</h4>
         <p>
            <span className="neon">Kevin</span> is a web developer with an extensive background in digital media and video production.
            His expertise includes <span className="neon">full-stack application development</span>, as well as cinematography and video-editing .
         </p>
         <p>
            He currently works at a financial S.A.A.S. company as a software engineer where he helps create
            and maintain websites for high traffic and data intensive investment platforms using technologies like 
            <span className="neon">React, Node, Express, PostgresQL, Jenkins, Docker, Apigee </span> among others.
         </p>
         Past video projects encompass online-educational series, documentary content and commercials for the web and broadcast. 
         Kevin is currently exploring the unique opportunities at the intersection of programming and interactive media 
         <p></p>
        </div>
        
        ),
    videoreel: (<iframe 
        title="youtube-video-portfolio"
        width="640"
        height="360"
        src="https://www.youtube.com/embed/lH3STqbFyR0" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>),
    // photography: (
    //     <InstagramEmbed
    //         url='https://www.instagram.com/p/CJeVK9kjDHy/'
    //         clientAccessToken="1098139597278224|515584bdedda56fe6480d429763cdfd9"
    //         maxWidth={320}
    //         hideCaption={false}
    //         containerTagName='div'
    //         protocol=''
    //         injectScript
    //         onLoading={() => {}}
    //         onSuccess={() => {}}
    //         onAfterRender={() => {}}
    //         onFailure={() => {}}
    //         >
    //     </InstagramEmbed>),
    help: (
        <div>
            <h4>Available Commands</h4>
            <ul> 
                <li>
                    <a>about</a>
                    <span>- who made this?</span>
                </li>
                <li>
                    <a>download-resume</a>
                    <span>- download a pdf of Kevin's work history</span>
                </li>
                <li>
                    <a>video-reel</a>
                    <span>- a video compilation of past cinematography projects</span>
                </li>
                {/* <li>
                    <a>web-projects</a>
                    <span>- links to web applications Kevin has worked on</span>
                </li> */}
                {/* <li>
                    <a>photography</a>
                    <span>- a collection of images Kevin has taken</span>
                </li> */}
                <li>
                    <a>help</a>
                    <span>- some words you can type, we all need help sometimes.</span>
                </li>
                <li>
                    <a>clear</a>
                    <span>- clear the terminal of all content.</span>
                </li>
            </ul>
            <p></p>
        </div>
    ),
    downloadresume: (<div></div>),
}

export const callCommand = (inputText) => {
    const sanitizedInput = inputText.trim().toLowerCase().replace('-','')

    if(sanitizedInput === 'downloadresume'){
        const link = document.createElement("a");
        link.download = 'Kevin_Ziechmann_Software_Developer';
        link.href = 'https://kevinziechmann.com/static/media/Kevin_Ziechmann_Software_Developer.pdf';
        link.click();
        link.remove();
    }
    if(Object.keys(commands).includes(sanitizedInput)){
        return commands[sanitizedInput]
    } else{
        return (<div className="error-message"> {`Error: command "${sanitizedInput}" not found`} <p></p></div>)
    }
}