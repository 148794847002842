import React, {useEffect, useState, useCallback} from "react";
import { callCommand, commands } from '../utilities/callCommands'
import './TerminalContent.css'
import './Terminal.css'

function Terminal({
    welcomeMessage,
    navContent = '',
    terminalPrompt
 }){
    const cliInput = React.createRef();
    const [inputText, setInputText] = useState('');
    const [activeProgram, setActiveProgram] = useState([]);
    const [history, setHistory] = useState(0);

    const commandsAvailable = [...Object.keys(commands),'clear']

    const handleKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        const inputText = cliInput.current.textContent
        event.preventDefault()
        if (keyCode === 13 || key === "Enter") {
            if(inputText.trim().toLowerCase() === 'clear'){
                setActiveProgram([])
                setInputText('')
                const scrollingElement = (document.scrollingElement || document.body);
                scrollingElement.scrollTop = scrollingElement.scrollHeight + 150;
            } else{
                const toAppend = callCommand(inputText)
                setActiveProgram(activeProgram => [...activeProgram,toAppend])
                setInputText('')
            }
        } else if(key === "Backspace"){
            setInputText(inputText => inputText.slice(0, inputText.length-1));
        } else if(
            (keyCode > 47 && keyCode < 58)   || // number keys
            (keyCode > 64 && keyCode < 91)   || // letter keys
            (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
            keyCode == 32 //spacebar
        ) {
            setInputText(inputText => inputText += key);
        } else if(keyCode == 38){ //up
            setInputText(commandsAvailable[history])
            setHistory(history => (history+1) % commandsAvailable.length)
        } else if(keyCode == 40){ //down
            setInputText(commandsAvailable[history])
            setHistory(history => (history-1 + commandsAvailable.length) % commandsAvailable.length)
        }
    }, [cliInput]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
        window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        const focusTextInput = setInterval(() => {
            cliInput.current.focus()
        }, 1000);
        return () => clearInterval(focusTextInput);
      }, [cliInput]);

    const addContent = (command) =>{
        const toAppend = callCommand(command)
        setActiveProgram(activeProgram => [...activeProgram,toAppend])
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight + 150;
    }

    return (
        <main className="terminal">
            <nav>
                <ul className="nav-links">
                    {Object.keys(commands).map((command, i) => {
                    return <li key={i} onClick={() => {addContent(command)}}>
                            {command == 'downloadresume' ? 'resume' : command}/
                        </li>
                    })}
                </ul>
            </nav>
            <div className="terminal-main">
                <p>{welcomeMessage} </p>
                {activeProgram.map(((child,  i) => React.cloneElement(child, { key:  i })))}
            </div>
            <span className="command-line">
                <span className="command-line-prompt">{terminalPrompt + " "} </span>
                <span 
                    className="command-line-input" 
                    ref={cliInput} 
                    spellCheck="false"
                >
                    {inputText}
                </span>
            </span>
            
        </main>
    );
}    

export default Terminal