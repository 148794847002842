import React from "react";
import {commands} from "./utilities/callCommands.js"
import "./App.css";
import Terminal from "./components/Terminal";

const welcomeMessage = <span>Hello and welcome to my site.
Please enter a command in the terminal to navigate
or click the links above.

Type 'help' below for a list of <a>commands.</a> </span>

const title =`
  ██╗░░██╗███████╗██╗░░░██╗██╗███╗░░██╗
  ██║░██╔╝██╔════╝██║░░░██║██║████╗░██║  
  █████═╝░█████╗░░╚██╗░██╔╝██║██╔██╗██║  
  ██╔═██╗░██╔══╝░░░╚████╔╝░██║██║╚████║  
  ██║░╚██╗███████╗░░╚██╔╝░░██║██║░╚███║  
  ╚═╝░░╚═╝╚══════╝░░░╚═╝░░░╚═╝╚═╝░░╚══╝  

  ███████╗██╗███████╗░█████╗░██╗░░██╗███╗░░░███╗░█████╗░███╗░░██╗███╗░░██╗
  ╚════██║██║██╔════╝██╔══██╗██║░░██║████╗░████║██╔══██╗████╗░██║████╗░██║
  ░░███╔═╝██║█████╗░░██║░░╚═╝███████║██╔████╔██║███████║██╔██╗██║██╔██╗██║
  ██╔══╝░░██║██╔══╝░░██║░░██╗██╔══██║██║╚██╔╝██║██╔══██║██║╚████║██║╚████║
  ███████╗██║███████╗╚█████╔╝██║░░██║██║░╚═╝░██║██║░░██║██║░╚███║██║░╚███║
  ╚══════╝╚═╝╚══════╝░╚════╝░╚═╝░░╚═╝╚═╝░░░░░╚═╝╚═╝░░╚═╝╚═╝░░╚══╝╚═╝░░╚══╝`

const prompt = "$:";

const addNavContent = () =>{

}

function App() {
  return (
    <div>
      <header>
        <h1 className="terminal-header">
          {title}
        </h1>
      </header>
      <Terminal
        welcomeMessage={welcomeMessage}
        terminalPrompt={prompt}
      />
    </div>
  );
}

export default App;